import React, {Component} from 'react';
import firebase from "firebase";
import 'firebase/database'
import './textArea.css'

class TextArea extends Component {
    constructor(props) {
        super(props);

        this.state = {document: {}, loading: true};
    }

    componentDidMount() {
        console.log('componentDidMount. documentID', this.props.documentID);

        this.initFirebaseDB(this.props.documentID);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        console.log('shouldComponentUpdate');

        if (this.props.documentID !== nextProps.documentID) {
            this.removeDatabaseListener();
            this.setState({document: {}, loading: true, expired: false});
            this.initFirebaseDB(nextProps.documentID);
            return true;
        }

        return this.state !== nextState;
    }

    initFirebaseDB = async (documentID) => {
        this.ref = firebase.database().ref('documents').child(documentID);

        let snapshot = await this.ref.once('value');

        if (!snapshot.exists()) {
            this.setState({expired: true, loading: false});
            return;
        }

        this.ref.on("child_added", snapshot => {
            console.log('child_added', snapshot);
            this.onChildAddedOrChanged(snapshot);
        });

        this.ref.on("child_changed", snapshot => {
            console.log('child_changed', snapshot);
            this.onChildAddedOrChanged(snapshot);
        });
    };

    componentWillUnmount() {
        this.ref.off('child_added');
        this.ref.off('child_changed');
        clearTimeout(this.timeout);
    }

    removeDatabaseListener = () => {
        this.ref.off('child_added');
        this.ref.off('child_changed');
    };

    onChildAddedOrChanged(snapshot) {
        if (snapshot) {
            console.log('document=', snapshot.val());
            this.setState({document: snapshot.val(), loading: false});
            console.log('onChildAddedOrChanged state=', this.state);
        }
    }

    onContentChanged = (value) => {
        this.setState({document: {...this.state.document, content: value, updated_at: Date.now()}});

        console.log('onContentChanged state=', this.state);

        this.timeout = setTimeout(this.updateValue, 100);
    };

    updateValue = (value) => {
        this.ref.set({data: this.state.document});
    };

    render() {
        const {document, expired, loading} = this.state;
        const {content} = document;

        console.log('textArea render content=', content);

        return (
            <div>
                {expired ? <h2>Document is expired or doesn't exist</h2> :
                    <textarea
                        placeholder={loading ? 'Loading, please wait...' : 'Write and sync in same place from anywhere...'}
                        className='box'
                        disabled={loading}
                        value={content || ''}
                        onChange={(e) => {
                            this.onContentChanged(e.target.value)
                        }}/>
                }
            </div>
        );
    }
}

export default TextArea;
