import React, {Component} from 'react';
import './App.css';
import firebase from "firebase";
import TextArea from "./textArea/textArea";

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {documentID: this.props.match.params.documentID};

        this.initFirebaseApp();
    }

    initFirebaseApp = () => {
        const config = {
            apiKey: "AIzaSyBDCO6vMLNyVTG7f5uDUVIEWLMqhnSWA1k",
            authDomain: "same-boat.firebaseapp.com",
            databaseURL: "https://same-boat.firebaseio.com",
            projectId: "same-boat",
            storageBucket: "same-boat.appspot.com",
            messagingSenderId: "140734014251",
            appId: "1:140734014251:web:29bca96d80fb25355b9b6e",
            measurementId: "G-LLZ3VW6TE0"
        };

        firebase.initializeApp(config);
        firebase.analytics();
    };

    createNewDocument = () => {
        console.log('createNewDocument()');

        const xhr = new XMLHttpRequest();

        xhr.addEventListener('load', () => {
            if (xhr.status === 200) {
                console.log('createNewDocument response', xhr.response);

                let documentID = JSON.parse(xhr.response).documentID;
                console.log('createNewDocument documentID=', documentID);
                window.history.pushState({}, null, '/' + documentID);
                this.setState({documentID, loading: false, error: false});
            } else {
                this.setState({loading: false, error: true});
                console.log('createNewDocument error=', xhr.response);
            }
        });
        xhr.open('GET', 'https://us-central1-same-boat.cloudfunctions.net/widgets/createDocument');
        xhr.send();
    };

    componentDidMount() {
        const {documentID} = this.state;

        console.log("documentID=" + documentID);
        if (!documentID) {
            this.setState({loading: true, error: false});
            this.createNewDocument();
        }
    }

    render() {
        console.log('App render');
        const {documentID, loading, error} = this.state;

        if (loading) {
            return <div className="d-flex m-5 justify-content-center">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        }

        if (error || !documentID) {
            return <div className="App">
                <h2>An error has occurred. Try to refresh the page.</h2>
            </div>
        }

        return (
            <div className="App">
                <div>
                    <TextArea documentID={documentID}/>
                    <button type="button" className="btn btn-success m-2" onClick={this.createNewDocument}>Create new
                        document
                    </button>
                    <br/>
                    <label className='m-2'>Offered by <a href="https://bikcrum.com" target="_blank"
                                                         rel="noopener noreferrer">Bikram</a></label>
                </div>
            </div>
        );
    }
}

export default App;
